<template>
  <div id="cookies-body">
    <div id="cookies-text">
      By using our website, you agree that
      <router-link style="color: #00F0FF" :to="{path: '/page/agreement', query: {back: 'promo'}}">we use cookies</router-link>
      <button id="cookies-btn" @click="cookiesMark">OK</button>
    </div>
  </div>
</template>

<script>
export default {

  name: "CookiesMessage",
  methods: {
    cookiesMark () {
      localStorage.setItem('isCookiesAccept', 'true');
      this.$store.commit('isCookiesAccept', 'true');
    }
  }
}

</script>

<style scoped>
#cookies-body {
  background-color: #343434;
  border: white solid 1px;
  border-radius: 10px 10px 0 0;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 999999;
  display: inline-block;
}

#cookies-text {
  margin: 15px;
  color: white;
  text-decoration: none;
  text-align: center;
  line-height: 2;
}

#cookies-btn {
  margin-left: 15px;
  padding: 3px 10px;
  background-color: #343434;
  border: solid white 2px;
  border-radius: 10px;
  color: white;
}

</style>