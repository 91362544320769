import jwt_decode from 'jwt-decode';
import moment from 'moment';

export default {

	data: () => ({
		breadcrumbs: null
	}),

	methods: {

		decode(str) {

			let txt = document.createElement("textarea");
			txt.innerHTML = str;
			return txt.value;
		},

		async redirect(route, message = null, type = null) {

			if (message)
				this.setFlashMessage(message, type);

			await this.$router.push(route);
		},

		setFlashMessage(message = null, type = null) {

			this.$store.commit('flashMessage', message);
			this.$store.commit('flashType', type);
			this.$route.meta.isFlashMessage = true;
		},

		getParseTokenData(data) {

			if (!data)
				return null;

			try {

				return jwt_decode(data);

			} catch (e) {

				return null;
			}
		},

		getDateTime(value) {

			return moment(String(value)).format('YYYY-MM-DD, HH:mm');
		},

		getCurrentFullDateTime() {

			return moment().format('YYYY-MM-DD HH:mm:ss');
		},

		getDate(value) {

			return moment(String(value)).format('YYYY-MM-DD');
		},

		getCurrencySymbol() {

			return '₪';
		},

		getFormatText(str) {

			return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br />$2');
		},

		formatTextInTags(str) {
			return (str + '').replace(/(\r\n|\n\r|\r|\n)/g, '');
		},

		showModal(data) {

			this.$emit('showModal', data);
		},

		getRoundNumber(value, precision = 2) {

			let number = Math.pow(10, precision);
			return Math.round(value * number) / number;
		},

		getPrice(price) {

			if (!price)
				return 0;

			price = String(price);
			return price.replace(/[,.]?0+$/, '');
		},

		saveBackRoute(route) {

			localStorage.setItem('backRoute', route);
		},

		loadBackRoute() {

			const backRoute = localStorage.getItem('backRoute');
			localStorage.removeItem('backRoute');

			return backRoute;
		},

		getCopyValue(value) {

			return JSON.parse(JSON.stringify(value));
		},

		getAppUrl(route = '') {

			return process.env.VUE_APP_URL + route;
		},

		getText (str) {

			if (typeof str === 'undefined' || str === null)
				return '';

			let tag = '<br />';
			return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + tag + '$2');
		},

		getInstagramUrl() {
			return 'https://www.instagram.com/boogy.fit/';
		},

		getWatsappUrl() {
			return 'https://wa.me/+972508720272';
		},

		getFacebookUrl() {
			return 'https://m.me/boogy.fit';
		}
	}
}