<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <div class="container">
      <router-link to="/admin" class="navbar-brand">Boogy Admin</router-link>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        </ul>
        <ul class="navbar-nav">
          <li class="nav-item" v-if="!this.$store.state.SAuth?.access?.isUser">
            <router-link to="/user/admin/login" class="nav-link">Login</router-link>
          </li>
          <li class="nav-item dropdown" v-if="this.$store.state.SAuth?.access?.isUser">
            <a class="nav-link dropdown-toggle" href="#" id="navbarScrollingDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              My profile
            </a>
            <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarScrollingDropdown">
              <li>
                <router-link to="/user/logout" class="dropdown-item">Logout ({{ this.$store.state.SAuth?.login }})</router-link>
              </li>
            </ul>
          </li>
        </ul>

      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'MenuAdminTop'
}
</script>

<style scoped>

</style>
