
export default {

	methods: {

		isUser() {

			return this.$store.state.SAuth?.access?.isUser;
		},

		isPartner() {

			return this.$store.state.SAuth?.access?.isPartner;
		},

		isManager() {

			return this.$store.state.SAuth?.access?.isManager;
		},

		isAdmin() {

			return this.$store.state.SAuth?.access?.isAdmin;
		},

		async checkUser() {

			if (this.isUser())
				return true;

			await this.redirect('/user/login', 'You must be logged in for this action');
		},

		async checkPartner() {

			if (!this.isUser())
				return await this.redirect('/user/partner/login');

			if (this.isPartner())
				return true;

			await this.redirect('/partner', 'You do not have permissions to this action');
		},

		async checkManager() {

			if (!this.isUser())
				return await this.redirect('/user/admin/login');

			if (this.isManager())
				return true;

			await this.redirect('/', 'You do not have permissions to this action');
		},

		async checkAdmin() {

			if (!this.isUser())
				return await this.redirect('/user/admin/login');

			if (this.isAdmin())
				return true;

			await this.redirect('/', 'You do not have permissions to this action');
		}

	}
}