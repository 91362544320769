<script>

class ApiHolder {

  status = null;

  constructor(response) {

    this.status = response?.status;

    if (response?.data?.message)
      this.message = response?.data?.message;

    if (response?.data?.errors)
      this.errors = response?.data?.errors;

    if (response?.data)
      this.data = response?.data;
  }

  isErrors() {

    return this.errors ? true : false;
  }

  isMessage() {

    return this.message ? true : false;
  }

  isAccessError() {

    return this.status == 403;
  }

  isAuthError() {

    return this.status == 401;
  }

  isSuccess() {

    return this.status == 200;
  }

}

export default ApiHolder;

</script>
