<template>
  <div class="container-fluid" v-if="this.$store.state.flashMessage">
    <div class="alert alert-dismissible fade show mt-3" role="alert"
     :class="['alert-' + (this.$store.state.flashType ? this.$store.state.flashType : 'danger')]">
      <span v-text="this.$store.state.flashMessage"></span>
      <button type="button" class="btn-close" @click="doCloseFlashMessage"></button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FlashMessage',

  methods: {

    doCloseFlashMessage() {

      this.$store.commit('flashMessage', null);
    }

  },

  watch: {

    $route(to, from) {

      if (!from.meta?.isFlashMessage && this.$store.state.flashMessage)
        this.$store.commit('flashMessage', null);
    }
  }
}
</script>

<style scoped>

</style>
