
export default {

	methods: {

		saveUserAuth(accessToken, refreshToken) {

			if (!accessToken || !refreshToken)
				return;

			this.$store.commit('SAuth', this.getParseTokenData(accessToken));
			this.$store.commit('accessToken', accessToken);
			this.$store.commit('refreshToken', refreshToken);

			localStorage.setItem('accessToken', accessToken);
			localStorage.setItem('refreshToken', refreshToken);
		},

		initUserAuth() {

			const {accessToken, refreshToken} = localStorage;
			if (!accessToken || !refreshToken)
				return;

			this.$store.commit('SAuth', this.getParseTokenData(accessToken));
			this.$store.commit('accessToken', accessToken);
			this.$store.commit('refreshToken', refreshToken);
		},

		deleteUserAuth() {

			this.$store.commit('SAuth', null);
			this.$store.commit('accessToken', null);
			this.$store.commit('refreshToken', null);

			localStorage.removeItem('accessToken');
			localStorage.removeItem('refreshToken');
		}
	}
}