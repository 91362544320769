<template>
  <div id="load-bar" v-if="this.$store.state.isLoad"></div>
</template>

<script>
export default {
  name: 'LoadBar'
}
</script>

<style scoped>

#load-bar {
  width: 46px;
  height: 46px;
  position: fixed;
  top: 50%;
  left: 50%;
  background: #000 url("~@/assets/img/f-loader.gif") no-repeat center center;
  margin: -23px 0px 0px -23px;
  z-index: 2010;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  opacity: 0.7;
}

#load-bar:after {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2000;
  content: " ";
  background: transparent;
}

</style>
