<template>
  <component :is="layout">
    <router-view :key="$route.fullPath"/>
  </component>
  <load-bar></load-bar>
  <cookies-message v-if="this.$store.state.isCookiesAccept === 'false'"/>
</template>

<script>
import UserMixin from '@/modules/user/mixins/UserMixin';
import LoadBar from "@/components/LoadBar";
import CookiesMessage from "@/components/CookiesMessage.vue";

export default {

  mixins: [UserMixin],
  components: {LoadBar, CookiesMessage},

  computed: {

    layout() {

      return this.$route.meta?.layout || 'LayoutMain';
    }
  },

  created() {
    if (localStorage.getItem('isCookiesAccept') === 'true') {
      this.$store.commit('isCookiesAccept', 'true');
    }
  },

  mounted() {

    this.initUserAuth();
  }
}

</script>

<style>

html,
body,
#app {
  height: 100%;
}

* {
  box-shadow: none !important;
}

.text-right {
  text-align: right !important;
}

.text-bold {
  font-weight: bold !important;
}

.container-nopad {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.form-group {
  margin-bottom: 10px;
}

.f-required {
  color: #ff0000;
  font-weight: bold;
  margin-left: 0.25rem;
}

.icon-fa {
  text-decoration: none;
  color: #555;
  font-size: 1.2rem !important;
  position: relative;
  top: 1px;
  opacity: 0.6;
  margin-right: 2px;
  margin-left: 2px;
}

.map-label {
  background-color: #2CDAE5;
  font-family: Roboto, Arial, sans-serif;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  min-width: 40px;
  padding: 6px 10px;
  border-radius: 20px;
  white-space: nowrap;
  margin-top: -2px !important;
}

.choices__inner {
  background: #FFF !important;
  border: 1px solid #ced4da !important;
}

.choices__input {
  background: #FFF !important;
}

.choices__list--multiple .choices__item {
  background-color: #e7e7e7 !important;
  border: 1px solid #e7e7e7 !important;
  color: #000 !important;
  border-radius: 5px !important;
  padding: 3px 4px !important;
}

.choices__list--dropdown, .choices__list[aria-expanded] {
  border: 1px solid #ced4da !important;
}

#qr-shaded-region_ {
  border-color: #717171 !important;
}

.layout-main .form-control {
  background: #F2F3F7 !important;
  border-color: #F2F3F7 !important;
  border-radius: 15px !important;
  padding: 15px 22px !important;
}

.button-back {
  background: #F2F2F2;
  color: #3F414E;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
}

.button-back-empty {
  background: #FFF;
  border: 1px solid #EBEAEC;
  color: #3F414E;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
}

.button-confirm {
  background: #343434;
  border: 1px solid #343434;
  color: #00F0FF;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
}

.btn-form {
  background: #343434 !important;
  text-decoration: none !important;
  border-radius: 38px !important;
  color: #00F0FF !important;
  font-weight: 700 !important;
  text-transform: uppercase !important;
  padding: 20px 50px !important;
  width: 100% !important;
  margin-bottom: 10px;
}

.h-top {
  padding-top: 16px;
}

.h-title {
  text-align: center;
  font-weight: 700;
  color: #A1A4B2;
  text-transform: uppercase;
}

.h-form {
  padding-top: 30px;
}

.h-subtext {
  color: #A1A4B2;
  font-weight: 500;
  text-align: center;
  padding-top: 15px;
}

.h-subtext a {
  color: #3F414E !important;
  text-decoration: none !important;
}

.h-bottom {
  padding-bottom: 30px;
  padding-top: 20px;
  text-align: center;
  text-transform: uppercase;
  color: #A1A4B2;
  font-weight: 500;
}

.h-bottom a {
  color: #3F414E !important;
  text-decoration: none !important;
}

.qr-code-print {
  width: 100%;
  max-width: 100%;
}

.help-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  min-height: 100vh;
  padding: 20px 15px;
  z-index: 1;
}

.help-block {
  max-width: 460px;
  margin: 0 auto 0 auto;
  border-radius: 10px;
  background: #FFF;
  padding: 20px;
  height: 500px;
  position: relative;
}

.help-block .close-div {
  position: absolute;
  top: 15px;
  right: 30px;
  display: block;
}

.help-block .close-div a {
  color: #616161 !important;
  text-decoration: none !important;
  font-size: 28px;
}

.help-block .title {
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 25px;
  text-align: center;
  padding-top: 40px;
}

.help-block p {
  font-size: 18px;
  margin-bottom: 20px;
}

.swiper-pagination-bullet {
  background: #D8D8D8 !important;
  opacity: 1 !important;
}

.swiper-pagination-bullet-active {
  background: #989898 !important;
}

.slider-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  min-height: 100vh;
  padding: 20px 15px;
  z-index: 1;
}

.slider-block {
  max-width: 460px;
  margin: 0 auto 0 auto;
  background: #FFF;
  border-radius: 10px;
}

.slider-block .inner-slide {
  padding: 15px 15px 50px 15px;
  background: #FFF;
  border-radius: 10px;
  text-align: center;
  min-height: 580px;
}

.slider-block .inner-slide .image {
  padding-top: 10px;
}

.slider-block .inner-slide .image img {
  max-width: 100%;
  width: 100%;
}

.slider-block .inner-slide-1 .image {
  padding-top: 20px;
}

.slider-block .inner-slide-1 .image img {
  max-width: 390px;
}

.slider-block .inner-slide-2 .image {
  padding-top: 20px;
  padding-bottom: 20px;
}

.slider-block .inner-slide-2 .image img {
  max-width: 280px;
}

.slider-block .inner-slide-3 .image {
  padding-top: 30px;
  padding-bottom: 20px;
}

.slider-block .inner-slide-3 .image img {
  max-width: 270px;
}

.slider-block .inner-slide-3 .chbs {
  padding-top: 10px;
}

.slider-block .inner-slide-3 .chbs label {
  position: relative;
  top: -1px;
}

.slider-block .inner-slide-3 .form-check-input:checked {
  background-color: #000000 !important;
  border-color: #000000 !important;
}

.slider-block .title {
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 20px;
}

.slider-block .description {
  font-size: 17px;
}

.promo-slider-block .swiper-pagination-fraction,
.promo-slider-block .swiper-pagination-custom,
.promo-slider-block .swiper-horizontal > .swiper-pagination-bullets,
.promo-slider-block .swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: auto;
  top: 10px;
  left: 0;
}

.section-review .swiper-pagination-bullet {
  background: #B7B7B7 !important;
}

.section-review .swiper-pagination-bullet-active {
  background: #2CDAE5 !important;
}

</style>
