<template>

  <div class="d-flex flex-column h-100">
    <div class="flex-shrink-0">
      <menu-partner-top></menu-partner-top>
      <div class="container mb-4">
        <breadcrumbs ref="breadcrumbs"></breadcrumbs>
        <flash-message></flash-message>
        <modal-alert ref="modal"></modal-alert>
        <router-view @setBreadcrumbs="setBreadcrumbs" @showModal="showModal" />
      </div>
    </div>
    <footer class="footer mt-auto py-3 bg-light">
      <div class="container text-center">
        <span class="text-muted">Boogy, ©2022</span>
      </div>
    </footer>
  </div>

</template>

<script>
import MenuPartnerTop from '@/components/MenuPartnerTop';
import FlashMessage from "@/components/FlashMessage";
import Breadcrumbs from "@/components/Breadcrumbs";
import ModalAlert from "@/components/ModalAlert";

export default {
  name: 'LayoutPartner',
  components: {Breadcrumbs, MenuPartnerTop, FlashMessage, ModalAlert},

  methods: {

    setBreadcrumbs(data) {

      this.$refs['breadcrumbs'].setBreadcrumbs(data);
    },

    showModal(data) {

      this.$refs['modal'].showModal(data);
    }
  }
}

</script>

<style scoped>


</style>
