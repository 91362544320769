import {createRouter, createWebHistory} from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import('@/modules/promo/actions/IndexAction')
  },
  {
    path: '/promo',
    name: 'promoIndex',
    component: () => import('@/modules/promo/actions/IndexAction')
  },
  {
    path: '/user/login',
    name: 'userLogin',
    component: () => import('@/modules/user/actions/LoginAction')
  },
  {
    path: '/user/register',
    name: 'userRegister',
    component: () => import('@/modules/user/actions/RegisterAction')
  },
  {
    path: '/user/recover',
    name: 'userRecover',
    component: () => import('@/modules/user/actions/RecoverAction')
  },
  {
    path: '/user/confirm',
    name: 'userConfirm',
    component: () => import('@/modules/user/actions/ConfirmAction')
  },
  {
    path: '/user/password/change',
    name: 'userPasswordChange',
    component: () => import('@/modules/user/actions/password/ChangeAction')
  },
  {
    path: '/user/edit',
    name: 'userEdit',
    component: () => import('@/modules/user/actions/EditAction')
  },
  {
    path: '/user/logout',
    name: 'userLogout',
    component: () => import('@/modules/user/actions/LogoutAction')
  },
  {
    path: '/user/password/edit',
    name: 'userPasswordEdit',
    component: () => import('@/modules/user/actions/password/EditAction')
  },
  {
    path: '/page/:alias',
    name: 'pageView',
    component: () => import('@/modules/page/actions/ViewAction')
  },
  {
    path: '/place',
    name: 'placeIndex',
    component: () => import('@/modules/place/actions/IndexAction')
  },
  {
    path: '/place/map',
    name: 'placeMap',
    component: () => import('@/modules/place/actions/MapAction')
  },
  {
    path: '/place/view/:id',
    name: 'placeView',
    component: () => import('@/modules/place/actions/ViewAction')
  },
  {
    path: '/place/code/view',
    name: 'placeCodeView',
    component: () => import('@/modules/place/actions/code/ViewAction')
  },
  {
    path: '/place/code/scan/:id',
    name: 'placeCodeScan',
    component: () => import('@/modules/place/actions/code/ScanAction')
  },
  {
    path: '/order/view/:id',
    name: 'orderView',
    component: () => import('@/modules/order/actions/ViewAction')
  },
  {
    path: '/order',
    name: 'orderIndex',
    component: () => import('@/modules/order/actions/IndexAction')
  },
  {
    path: '/order/finish',
    name: 'orderFinish',
    component: () => import('@/modules/order/actions/FinishAction')
  },
  {
    path: '/pay/process/:id',
    name: 'payProcess',
    component: () => import('@/modules/pay/actions/ProcessAction')
  },
  {
    path: '/pay/card/process/:id',
    name: 'payCardProcess',
    component: () => import('@/modules/pay/actions/card/ProcessAction')
  },
  {
    path: '/pay/card/info',
    name: 'payCardInfo',
    component: () => import('@/modules/pay/actions/card/InfoAction')
  },
  {
    path: '/debug',
    name: 'debugIndex',
    component: () => import('@/modules/debug/actions/IndexAction')
  },
  {
    path: '/admin',
    name: 'adminIndex',
    component: () => import('@/modules/admin/actions/IndexAction'),
    meta: {
      layout: 'LayoutAdmin'
    }
  },
  {
    path: '/user/admin',
    name: 'userAdminIndex',
    component: () => import('@/modules/user/actions/admin/IndexAction'),
    meta: {
      layout: 'LayoutAdmin'
    }
  },
  {
    path: '/user/admin/edit/:id',
    name: 'userAdminEdit',
    component: () => import('@/modules/user/actions/admin/EditAction'),
    meta: {
      layout: 'LayoutAdmin'
    }
  },
  {
    path: '/user/admin/add',
    name: 'userAdminAdd',
    component: () => import('@/modules/user/actions/admin/EditAction'),
    meta: {
      layout: 'LayoutAdmin'
    }
  },
  {
    path: '/user/admin/login',
    name: 'userAdminLogin',
    component: () => import('@/modules/user/actions/admin/LoginAction'),
    meta: {
      layout: 'LayoutAdmin'
    }
  },
  {
    path: '/partner',
    name: 'partnerIndex',
    component: () => import('@/modules/partner/actions/IndexAction'),
    meta: {
      layout: 'LayoutPartner'
    }
  },
  {
    path: '/user/partner/login',
    name: 'userPartnerLogin',
    component: () => import('@/modules/user/actions/partner/LoginAction'),
    meta: {
      layout: 'LayoutPartner'
    }
  },
  {
    path: '/place/admin',
    name: 'placeAdminIndex',
    component: () => import('@/modules/place/actions/admin/IndexAction'),
    meta: {
      layout: 'LayoutAdmin'
    }
  },
  {
    path: '/place/admin/add',
    name: 'placeAdminAdd',
    component: () => import('@/modules/place/actions/admin/EditAction'),
    meta: {
      layout: 'LayoutAdmin'
    }
  },
  {
    path: '/place/admin/edit/:id',
    name: 'placeAdminEdit',
    component: () => import('@/modules/place/actions/admin/EditAction'),
    meta: {
      layout: 'LayoutAdmin'
    }
  },
  {
    path: '/place/partner',
    name: 'placePartnerIndex',
    component: () => import('@/modules/place/actions/partner/IndexAction'),
    meta: {
      layout: 'LayoutPartner'
    }
  },
  {
    path: '/place/partner/:id',
    name: 'placePartnerView',
    component: () => import('@/modules/place/actions/partner/ViewAction'),
    meta: {
      layout: 'LayoutPartner'
    }
  },
  {
    path: '/company/admin',
    name: 'companyAdminIndex',
    component: () => import('@/modules/company/actions/admin/IndexAction'),
    meta: {
      layout: 'LayoutAdmin'
    }
  },
  {
    path: '/company/admin/add',
    name: 'companyAdminAdd',
    component: () => import('@/modules/company/actions/admin/EditAction'),
    meta: {
      layout: 'LayoutAdmin'
    }
  },
  {
    path: '/company/admin/edit/:id',
    name: 'companyAdminEdit',
    component: () => import('@/modules/company/actions/admin/EditAction'),
    meta: {
      layout: 'LayoutAdmin'
    }
  },
  {
    path: '/order/admin',
    name: 'orderAdminIndex',
    component: () => import('@/modules/order/actions/admin/IndexAction'),
    meta: {
      layout: 'LayoutAdmin'
    }
  },
  {
    path: '/:pathMatch(.*)*',
    component: () => import('@/modules/base/actions/Error404Action')
  },
];

const SRouter = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default SRouter;
