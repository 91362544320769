<template>
  <nav aria-label="breadcrumb" v-if="breadcrumbsList">
    <ol class="breadcrumb">
      <li class="breadcrumb-item" v-for="(breadcrumbsData, key) in breadcrumbsList" :key="key">
        <a href="#" @click.prevent="this.$router.push(breadcrumbsData[1])" v-if="Array.isArray(breadcrumbsData)">{{ breadcrumbsData[0] }}</a>
        <span v-else>{{ breadcrumbsData }}</span>
      </li>
    </ol>
  </nav>
</template>

<script>
export default {
  name: 'Breadcrumbs',

  data: () => ({
    breadcrumbsList: null
  }),

  methods: {

    setBreadcrumbs(data = null) {

      this.breadcrumbsList = data;
    }
  }
}
</script>

<style scoped>

</style>
