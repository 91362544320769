import axios from 'axios';
import ApiHolder from '@/modules/api/holders/ApiHolder';

export default {

	data: () => ({
		errors: {}
	}),

	methods: {

		clearErrors() {

			this.errors = {};
		},

		async sendApiGet(route, options = {}) {

			return await this.sendApiRequest(route, {}, {
				method: 'get',
				...options
			});
		},

		async sendApiPost(route, params = {}, options = {}) {

			return await this.sendApiRequest(route, params, {
				method: 'post',
				...options
			});
		},

		async sendApiDelete(route, options = {}) {

			return await this.sendApiRequest(route, {}, {
				method: 'delete',
				...options
			});
		},

		async sendApiRequest(route, params = {}, options = {}) {

			if (!options?.isDisableLoad)
				this.$store.commit('isLoad', true);

			if (route && route instanceof Array) {

				if (route[1] && route[1] instanceof Object) {

					const params = new URLSearchParams(route[1]);
					route = route[0] + '?' + params.toString();
				} else {

					route = route[0];
				}
			}

			let headers = {};
			if (this.$store.state.accessToken)
				headers['Authorization'] = 'Bearer ' + this.$store.state.accessToken;

			const res = await axios.request({
				method: options.method || 'post',
				url: process.env.VUE_APP_API_URL + route,
				headers: {
					fkey: process.env.VUE_APP_API_KEY,
					...headers
				},
				data: params
			}).then((response) => {

				return this.parseResponse(response, options);
			}).catch((error) => {

				return this.parseResponse(error?.response, options);
			}).finally(() => {

				this.$store.commit('isLoad', false);
			});

			return res;
		},

		async parseResponse(response, options) {

			const responseHolder = new ApiHolder(response);

			const isModalMessage = options?.isModalMessage || false;

			if (responseHolder.isErrors())
				this.errors = responseHolder.errors;

			if (!isModalMessage && responseHolder.isMessage())
				this.setFlashMessage(responseHolder.message);

			switch (responseHolder.status) {

				case 400:

					if (!isModalMessage && responseHolder.isMessage())
						await this.$router.push('/');

					if (isModalMessage && responseHolder.isMessage())
						this.showModal(responseHolder.message);

					break;

				case 401:

					if (this.isUser()) {

						this.$store.commit('SAuth', null);
						this.$store.commit('accessToken', null);
						this.$store.commit('refreshToken', null);

						localStorage.removeItem('accessToken');
						localStorage.removeItem('refreshToken');
					}

					await this.redirect('/user/login');
					break;

				case 403:

					if (!responseHolder.isMessage())
						this.setFlashMessage('You do not have permissions to this action');

					await this.redirect('/');
					break;

				case 404:

					if (!responseHolder.isMessage())
						this.setFlashMessage('Entity not found');

					this.$router.go(-1);
					break;

				// TODO:
			}

			return responseHolder;
		}

	}
}