<template>
  <div class="modal show d-block" tabindex="-1" role="dialog" v-if="isShow">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ title }}
          </h5>
          <button type="button" class="btn-close" aria-label="Close" @click="setShow(false)"></button>
        </div>
        <div class="modal-body">
          <span v-html="text"></span>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="setShow(false)">OK</button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-backdrop show" v-if="isShow"></div>
</template>

<script>
export default {
  name: 'ModalAlert',

  data: () => ({
    title: 'Information',
    content: null,

    isShow: false
  }),

  methods: {

    showModal(data) {

      this.title = data?.title || 'Information';
      this.text = data instanceof Object ? data?.text : data;

      this.setShow(true);
    },

    setShow(isShow) {

      this.isShow = isShow;
    }
  }
}
</script>

<style scoped>

</style>
