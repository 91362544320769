<template>
  <div class="h-100 layout-main">
    <flash-message></flash-message>
    <modal-alert ref="modal"></modal-alert>
    <router-view @showModal="showModal" />
  </div>
</template>

<script>
import FlashMessage from "@/components/FlashMessage";
import ModalAlert from "@/components/ModalAlert";

export default {
  name: 'LayoutMain',
  components: {ModalAlert, FlashMessage},

  methods: {

    showModal(data) {

      this.$refs['modal'].showModal(data);
    }
  }
}

</script>

<style scoped>

</style>
