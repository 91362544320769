import {createApp} from 'vue';
import App from './App.vue';
import SRouter from '@/system/SRouter';
import SStore from '@/system/SStore';
import VueGtag from 'vue-gtag';

import 'font-awesome/css/font-awesome.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';

import LayoutMain from '@/layouts/LayoutMain';
import LayoutAdmin from '@/layouts/LayoutAdmin';
import LayoutPartner from '@/layouts/LayoutPartner';

import BaseMixin from '@/modules/base/mixins/BaseMixin';
import ApiMixin from '@/modules/api/mixins/ApiMixin';
import AccessMixin from '@/modules/access/mixins/AccessMixin';

const app = createApp(App);
app.mixin(BaseMixin);
app.mixin(ApiMixin);
app.mixin(AccessMixin);

app.component('LayoutMain', LayoutMain);
app.component('LayoutAdmin', LayoutAdmin);
app.component('LayoutPartner', LayoutPartner);

app.use(SStore);
app.use(SRouter);

app.use(VueGtag, {
    config: {
        id: 'G-K5RLPZ3TW0',
    },
}, SRouter);

app.mount('#app');
