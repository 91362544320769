import {createStore} from 'vuex'

export default createStore({

  state: {

    mapCenter: {
      lat: 32.816057,
      lng: 35.003125
    },
    mapZoom: 13,

    SAuth: null,
    accessToken: null,
    refreshToken: null,

    flashMessage: null,
    flashType: null,
    isLoad: false,
    isCookiesAccept: 'false',
  },

  mutations: {

    SAuth(state, data = null) {

      state.SAuth = data;
    },

    accessToken(state, accessToken = null) {

      state.accessToken = accessToken;
    },

    refreshToken(state, refreshToken = null) {

      state.refreshToken = refreshToken;
    },

    flashMessage(state, message = null) {

      state.flashMessage = message;
    },

    flashType(state, type = null) {

      state.flashType = type;
    },

    isLoad(state, isLoad) {

      state.isLoad = isLoad;
    },

    isCookiesAccept(state, accept) {

      state.isCookiesAccept = accept;
    }
  }
})
